import {createStyles, Modal, Select, Space, Textarea, TextInput} from "@mantine/core";
import {useState} from "react";
import {TableScrollArea} from "./TableSticky";
import {Column, ColumnType} from "../../general/general";

const useStyles = createStyles((theme, _, getRef) => ({
    editableText: {
        '&[contenteditable="true"]': {
            border: '1px solid #e9ecef',
            borderRadius: '5px',
        }
    },

    container: {
        padding: '3px',
    },

    comment: {
        maxWidth: '100px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },

    chart: {
        minWidth: '600px',
        display: 'flex',
        alignItems: 'center',

    }
}));

export interface TableRowProps {
    value: any;
    editing?: boolean;
    onChange?: (value: any) => void;
    pressed?: boolean;
}

export function TableRowString({ value, editing = false, onChange }: TableRowProps) {
    const { cx, classes } = useStyles();

    if (editing) {
        return <TextInput value={value} onChange={event => onChange ? onChange(event.target.value) : null}/>
    }

    return <div>
        {value}
    </div>
}

export function TableRowStringList({ value, editing = false, onChange }: TableRowProps) {
    const { cx, classes } = useStyles();

    // if (editing) {
    //     return <TextInput value={value} onChange={event => onChange ? onChange(event.target.value) : null}/>
    // }

    return <div>
        {value.map((item: any, i: number) => {
            return <span key={i}>
                <span>{item}</span>
                {i < (value.length - 1) ? <span>,  </span> : null}
            </span>
        })}
    </div>
}

export function TableRowComment({ value, editing = false, onChange }: TableRowProps) {
    const { cx, classes } = useStyles();
    const [opened, setOpened] = useState<boolean>(false);

    if (editing) {
        return <Textarea value={value} onChange={event => onChange ? onChange(event.target.value) : null}/>
    }

    return <div>
        <div className={cx(classes.comment)}  onClick={event => {
            event.preventDefault();
            if (!opened)
                setOpened(true)
        }}>
            {value}
        </div>
        <div>
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                title={"Kommentar"}
            >
                <Textarea value={value} onChange={event => onChange ? onChange(event.target.value) : null}/>
            </Modal>
        </div>
    </div>
}

export function TableRowGradeDropdown({ value, editing = false, onChange }: TableRowProps) {
    const { cx, classes } = useStyles();

    return <div>
        <Select
            //label="Your favorite framework/library"
            placeholder="---"
            data={[
                { value: 'IG', label: 'IG' },
                { value: 'G', label: 'G' },
                { value: 'VG', label: 'VG' },
                { value: 'undefined', label: '---' },
            ]}
            value={value}
            onChange={onChange}
            sx={{ maxWidth: '70px' }}
        />
    </div>
}

export function TableRowCourseGrade({ value, editing = false, onChange }: TableRowProps) {
    if (value === undefined || value.length === 0) {
        return <></>
    }

    let content = [];
    for (let object of value) {
        for (let key in object) {
            content.push({key, value: object[key]});
        }
    }

    return <div>
        {content.map((all: any, index: number) => {
            return <div key={index}>
                {all.key}: {all.value}
            </div>
        })}
    </div>
}

export function TableRowNamedObject({ value, editing = false, onChange }: TableRowProps) {
    const { cx, classes } = useStyles();

    if (editing) {
        return <TextInput value={value} onChange={event => onChange ? onChange(event.target.value) : null}/>
    }

    return <div>
        {value === null ? <></> : value.name}
    </div>
}

export function TableRowNamedObjectList({ value, editing = false, onChange }: TableRowProps) {
    const { cx, classes } = useStyles();

    // if (editing) {
    //     return <TextInput value={value} onChange={event => onChange ? onChange(event.target.value) : null}/>
    // }

    return <div>
        {value.map((all: any, i: number) => {
            return <span key={i}>
                <span>{all.name}</span>
                {i < (value.length - 1) ? <span>,  </span> : null}
            </span>
        })}
    </div>
}

export function TableRowGradeChart({ value, editing = false, onChange, pressed}: TableRowProps) {
    const { cx, classes } = useStyles();

    const data = [
        {
            '1': Math.random() < 0.5 ? 'G' : 'VG',
            '2': Math.random() < 0.5 ? 'G' : 'VG',
            '3': Math.random() < 0.5 ? 'G' : 'VG',
            '4': Math.random() < 0.5 ? 'G' : 'VG',
            '5': Math.random() < 0.5 ? 'G' : 'VG',
            '6': Math.random() < 0.5 ? 'G' : 'VG'
        },
    ]

    const columns = [
        new Column('1', '1', ColumnType.Text, [], false),
        new Column('2', '2', ColumnType.Text, [], false),
        new Column('3', '3', ColumnType.Text, [], false),
        new Column('4', '4', ColumnType.Text, [], false),
        new Column('5', '5', ColumnType.Text, [], false),
        new Column('6', '6', ColumnType.Text, [], false),
    ];

    const data2 = [
        { '1': Math.random() < 0.5 ? 'G' : 'VG', '2': Math.random() < 0.5 ? 'G' : 'VG' },
    ]

    const columns2 = [
        new Column('HTML Sida', '1', ColumnType.Text, [], false),
        new Column('NodeJS App', '2', ColumnType.Text, [], false),
    ];

    if (!pressed) {
        // return <Select data={[ { value: 'VG' }, { value: 'G' }, { value: 'IG' }, { value: '--' } ]} onChange={onChange}/>
        return <div>{Math.random() < 0.5 ? 'G' : 'VG'}</div>
    }

    return <div className={cx(classes.chart)}>
        <div>{Math.random() < 0.5 ? 'G' : 'VG'}</div>
        {/*<Select data={[ { value: 'VG' }, { value: 'G' }, { value: 'IG' }, { value: '--' } ]} value={'G'} sx={{ width: '70px'}}/>*/}
        <Space mr={10} />
        <TableScrollArea data={data} columns={columns} onChange={() => {}} />
        <Space mr={10} />
        <TableScrollArea data={data2} columns={columns2} onChange={() => {}} />
    </div>

    // const data = [
    //     { goal: '1', grade: 'G', covered: 'Uppg. 1' },
    //     { goal: '2', grade: 'G', covered: 'Uppg. 1' },
    //     { goal: '3', grade: 'G', covered: 'Uppg. 1' },
    //     { goal: '4', grade: 'VG', covered: 'Uppg. 2' },
    //     { goal: '5', grade: 'VG', covered: 'Uppg. 2' },
    //     { goal: '6', grade: 'VG', covered: 'Uppg. 2' },
    // ]
    //
    // const columns = [
    //     new Column('Mål', 'goal', ColumnType.Text, [], false),
    //     new Column('Betyg', 'grade', ColumnType.GradeChoice, [], false),
    //     new Column('Täckt av', 'covered', ColumnType.Text, [], false),
    // ];
    //
    // const data2 = [
    //     { goal: 'HTML Sida', grade: 'G' },
    //     { goal: 'NodeJS App', grade: 'G' },
    // ]
    //
    // const columns2 = [
    //     new Column('Uppgift', 'goal', ColumnType.Text, [], false),
    //     new Column('Betyg', 'grade', ColumnType.GradeChoice, [], false),
    // ];
    //
    // if (!pressed) {
    //     return <Select data={[ { value: 'VG' }, { value: 'G' }, { value: 'IG' }, { value: '--' } ]} onChange={onChange}/>
    // }
    //
    // return <div className={cx(classes.chart)}>
    //     <Select data={[ { value: 'VG' }, { value: 'G' }, { value: 'IG' }, { value: '--' } ]} value={'G'}/>
    //     <TableScrollArea data={data} columns={columns} onChange={() => {}} />
    //     <TableScrollArea data={data2} columns={columns2} onChange={() => {}} />
    // </div>
}