import {useRecoilState} from "recoil";
import {programmesState} from "../state";
import {useNavigate, useParams} from "react-router-dom";
import {Accordion, Button, Container, createStyles, Divider, Drawer, Text, Timeline, Title} from "@mantine/core";
import {IconCircleCheck, IconCircleDashed, IconCircleDot} from "@tabler/icons";
import {useState} from "react";
import {EditCourseView, CreateCourseView} from "./components/CourseDrawer";
import {compareDates, getWeek} from "../utility";
import {TableScrollArea} from "../components/table/TableSticky";
import {Column, ColumnType, Course, Programme} from "../general/general";

/*

courses, yhnr, name, class list?

 */

const useStyles = createStyles((theme, _, getRef) => ({
    main: {
        overflow: 'hidden',
        maxHeight: '100vh'
    },

    container: {
        height: '100%',
        width: '100%',
        margin: 'auto',
        marginTop: '20px',
        overflow: 'scroll',
    },

    contentContainer: {
        margin: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },

    header: {
        width: '100%',
        height: '70px',
        borderBottom: '1px solid #e9ecef',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },

    coursesFullContainer: {
        height: '30px',
        display: 'flex',
        // flexDirection: 'row',
    },

    coursesContainer: {
        width: '50%',
        cursor: 'pointer',

        '&:hover': {
            border: '2px solid #e9ecef',
            borderRadius: '2px'
        }
    },

    coursesContainerEmpty: {
        width: '50%',
    },

    weekContainer: {
        width: '50%',
        height: '30px',
        display: 'flex',
        flexDirection: 'row',
    },

    weekBox: {
        width: '30px',
        height: '30px',
        backgroundColor: 'lightgreen',
        fontSize: '15px',
        lineHeight: '30px',
        textAlign: 'center',
        borderRight: '1px solid black',
    },

    weekCourseBox: {
        width: '30px',
        height: '30px',
        textAlign: 'center',
        fontSize: '15px',
        lineHeight: '30px',
        // backgroundColor: 'lightcyan',
        borderBottom: '1px solid black',
        borderRight: '1px solid black',
    }
}));

function prettyDate(date: Date): string {
    return date.getFullYear() + "-" + ("0"+(date.getMonth()+1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
}

/*

Kurser

Accordion where you can open the courses


 */

function CourseView({ programme, edit }: { programme: Programme, edit(course: Course): void }) {
    const { cx, classes } = useStyles();

    const createLeaps = (): Course[][] => {
        let list: Course[][] = [
            [],
            []
        ];

        for (let course of programme.courses) {
            if (getWeek(course.endDate) <= 26) {
                list[0].push(course);
            } else {
                list[1].push(course);
            }
        }

        return list;
    }

    const getWeeks = (leap: number): number[] => {
        let list = [];
        for (let i = 26 * leap + 1; i <= (26 * (leap + 1)); i++) {
            list.push(i);
        }
        return list;
    }

    const leap = (): any => {
        let leaps = createLeaps();

        return leaps.map((frame, index) => {
            let weeks = getWeeks(index);
            return <div key={index}>

                <div className={cx(classes.coursesFullContainer)}>
                    <div className={cx(classes.coursesContainerEmpty)}></div>
                    <div className={cx(classes.weekContainer)}>
                        {weeks.map((week, weekIndex) => {
                            return <div key={weekIndex} className={cx(classes.weekBox)}>{week}</div>
                        })}
                    </div>
                </div>

                {frame.map((course, courseIndex) => {
                    return <div key={courseIndex} className={cx(classes.coursesFullContainer)}>
                        <div className={cx(classes.coursesContainer)} onClick={() => edit(course)}>
                            {course.name} | {course.points} | {course.startDate.getDay()}/{course.startDate.getMonth()}/{course.startDate.getFullYear()} - {course.endDate.getDay()}/{course.endDate.getMonth()}/{course.endDate.getFullYear()}
                        </div>
                        <div className={cx(classes.weekContainer)}>
                            {weeks.map((week, weekIndex) => {
                                let start = getWeek(course.startDate);
                                let end = getWeek(course.endDate);
                                let is = week >= start && week < end;
                                return <div key={weekIndex} className={cx(classes.weekCourseBox)}>{is ? 16 : ''}</div>
                            })}
                        </div>
                    </div>
                })}

                <Divider sx={{ marginTop: '7px', marginBottom: '7px' }}/>
            </div>
        });
    }

    return <>
        {leap()}
    </>
}

enum ActiveDrawer {
    None,
    CreateCourse,
    EditCourse
}

export default function ProgrammeView() {
    const { cx, classes } = useStyles();
    const { id } = useParams();
    const navigate = useNavigate();

    const [activeDrawer, setActiveDrawer] = useState<ActiveDrawer>(ActiveDrawer.None);
    const [editCourse, setEditCourse] = useState<Course | null>(null);
    const [programmes, setProgrammes] = useRecoilState(programmesState);
    const [accordion, setAccordion] = useState<string[]>([]);
    const programme: Programme = programmes.find(all => all.yhnr === id) as Programme;

    if (programme === undefined) {
        return <>Något gick snett.</>
    }

    const now = new Date();

    const findActiveIndex = (): number => {
        // TODO: Fixa bugg om det är uppehåll (alltså kurs som inte börjat än men är fortfarande 'nästa' kurs)
        for (let i = 0; i < programme.courses.length; i++) {
            let course = programme.courses[i];
            if (compareDates(now, course.startDate) && !compareDates(now, course.endDate)) {
                return i;
            }
        }
        return programme.courses.length - 1;
    }

    const activeIndex = findActiveIndex();

    const coursesTimeline = (course: any, index: number): any => {
        let completed = index < activeIndex;
        let current = index === activeIndex;
        let weeks = ((course.endDate.getTime() - now.getTime()) / 1000 / 60 / 60 / 24).toFixed(0);
        let bullet = completed ? <IconCircleCheck/> : (current ? <IconCircleDot color="yellow"/> : <IconCircleDashed color="red"/>)
        return <Timeline.Item title={current ? (course.name + " - "  + weeks + " dagar kvar"): course.name} key={index}
                              bulletSize={24}
                              bullet={bullet}>
            <Text color="dimmed" size="sm">{prettyDate(course.startDate)} - Kurs beskrivning</Text>
        </Timeline.Item>
    }

    const onCreateCourses = (courses: Course[]) => {
        setActiveDrawer(ActiveDrawer.None);
        setProgrammes(programmes.map(all => {
            if (all !== programme) return all;

            return { ...all, courses: all.courses.concat(courses) }
        }))
    }

    const onEditCourse = (course: Course) => {
        setProgrammes(programmes.map(programme => {
            let inl = programme.courses.find(c => c.name === editCourse?.name);
            if (inl === undefined) return programme;

            let a = { ...programme };
            a.courses = a.courses.map(all => {
                if (all.name !== editCourse?.name) return all;

                return course;
            });
            return a;
        }))

        setEditCourse(null);
        setActiveDrawer(ActiveDrawer.None);
    }

    const data = [
        { namn: 'Anders Persson', email: 'anders@persson.se', phone: '070-9343410', company: 'Teaching AB', title: 'Administratör', role: 'Studeranderepresentant' },
    ]

    const columns = [
        new Column('Namn', 'namn', ColumnType.Text, [], false),
        new Column('Email', 'email', ColumnType.Text, [], false),
        new Column('Telefon', 'phone', ColumnType.Text, [], false),
        new Column('Företag', 'company', ColumnType.Text, [], false),
        new Column('Titel', 'title', ColumnType.Text, [], false),
        new Column('Roll', 'role', ColumnType.Text, [], false),
    ];

    return <div className={cx(classes.main)}>
        <Drawer opened={activeDrawer === ActiveDrawer.CreateCourse} onClose={() => setActiveDrawer(ActiveDrawer.None)} title={"Lägg till kursplaner"} size="xxl" padding="xl">
            <CreateCourseView onCreate={onCreateCourses}/>
        </Drawer>
        <Drawer opened={activeDrawer === ActiveDrawer.EditCourse}
                onClose={() => setActiveDrawer(ActiveDrawer.None)}
                title={"Redigera kursplan"}
                size="xxl"
                padding="xl">
            {editCourse && <EditCourseView onCreate={onEditCourse} defaultValue={editCourse} />}
        </Drawer>

        <div className={cx(classes.header)}>
            <Button sx={{ marginLeft: '6px' }} variant='outline' onClick={() => navigate('/planering')}>Tillbaka</Button>
            <Button sx={{ marginLeft: 'auto', marginRight: '6px', }} variant='outline' onClick={() => setActiveDrawer(ActiveDrawer.CreateCourse)}>Ny kursplan</Button>
        </div>

        <div className={cx(classes.container)}>
            <Container>
                <Title align="center">{programme.name}</Title>
            </Container>

            <Divider sx={{margin: '10px' }} />

            <Accordion value={accordion} sx={{ width: '100%' }} multiple={true} variant='separated' onChange={setAccordion}>
                <Accordion.Item value="courses">
                    <Accordion.Control ><Text>Kursplaner</Text></Accordion.Control>
                    <Accordion.Panel>

                        <CourseView programme={programme} edit={(course: Course) => {
                            setEditCourse(course);
                            setActiveDrawer(ActiveDrawer.EditCourse);
                        }}/>

                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="people">
                    <Accordion.Control><Text>Ledningsgrupp</Text></Accordion.Control>
                    <Accordion.Panel>
                        <TableScrollArea data={data} columns={columns} onChange={() => {}} closableColumns={false}/>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            {/*<div className={cx(classes.contentContainer)}>*/}
            {/*    <Container>*/}
            {/*        <Timeline active={activeIndex - 1}>*/}
            {/*            {programme.courses.map(coursesTimeline)}*/}
            {/*        </Timeline>*/}
            {/*    </Container>*/}

            {/*    <Container>*/}
            {/*        <h3>Ledningsgrupp</h3>*/}
            {/*        <TableScrollArea data={data} columns={columns} onChange={() => {}} closableColumns={false}/>*/}
            {/*    </Container>*/}
            {/*</div>*/}
        </div>
    </div>
}

// export default function ProgrammeView() {
//     const { cx, classes } = useStyles();
//     const { id } = useParams();
//     const navigate = useNavigate();
//
//     const [creating, setCreating] = useState<boolean>(false);
//     const [programmes, setProgrammes] = useRecoilState(programmesState);
//     const programme = programmes.find(all => all.yhnr === id);
//
//     if (programme === undefined) {
//         return <>Något gick snett.</>
//     }
//
//     const now = new Date();
//
//     const findActiveIndex = (): number => {
//         // TODO: Fixa bugg om det är uppehåll (alltså kurs som inte börjat än men är fortfarande 'nästa' kurs)
//         for (let i = 0; i < programme.courses.length; i++) {
//             let course = programme.courses[i];
//             if (compareDates(now, course.startDate) && !compareDates(now, course.endDate)) {
//                 return i;
//             }
//         }
//         return programme.courses.length - 1;
//     }
//
//     const activeIndex = findActiveIndex();
//
//     const coursesTimeline = (course: any, index: number): any => {
//         let completed = index < activeIndex;
//         let current = index === activeIndex;
//         let weeks = ((course.endDate.getTime() - now.getTime()) / 1000 / 60 / 60 / 24).toFixed(0);
//         let bullet = completed ? <IconCircleCheck/> : (current ? <IconCircleDot color="yellow"/> : <IconCircleDashed color="red"/>)
//         return <Timeline.Item title={current ? (course.name + " - "  + weeks + " dagar kvar"): course.name} key={index}
//                               bulletSize={24}
//                               bullet={bullet}>
//             <Text color="dimmed" size="sm">{prettyDate(course.startDate)} - Kurs beskrivning</Text>
//         </Timeline.Item>
//     }
//
//     const onCreate = (course: any) => {
//         setProgrammes(programmes.map(all => {
//             if (all !== programme) return all;
//
//             return { ...all, courses: [...all.courses, course] };
//         }))
//     }
//
//     return <div className={cx(classes.main)}>
//         <CourseDrawer opened={creating} onClose={() => setCreating(false)} onCreate={onCreate}/>
//
//         <div className={cx(classes.header)}>
//             <Button sx={{ marginLeft: '6px' }} variant='outline' onClick={() => navigate('/planering')}>Tillbaka</Button>
//             <Button sx={{ marginLeft: 'auto', marginRight: '6px', }} variant='outline' onClick={() => setCreating(true)}>Ny Kurs</Button>
//         </div>
//
//         <div className={cx(classes.container)}>
//             <Container>
//                 <Title align="center">{programme.name}</Title>
//             </Container>
//
//             <Divider sx={{margin: '10px' }} />
//
//             <div className={cx(classes.contentContainer)}>
//                 <Container>
//                     <Timeline active={activeIndex - 1}>
//                         {programme.courses.map(coursesTimeline)}
//                     </Timeline>
//                 </Container>
//
//                 <Container>
//                     <TableScrollArea data={[
//                         { namn: 'Anders Persson', email: 'anders@persson.se' },
//                         { namn: 'Anders Persson', email: 'anders@persson.se' },
//                         { namn: 'Anders Persson', email: 'anders@persson.se' },
//                         { namn: 'Anders Persson', email: 'anders@persson.se' },
//                         { namn: 'Anders Persson', email: 'anders@persson.se' },
//                         { namn: 'Anders Persson', email: 'anders@persson.se' },
//                         { namn: 'Anders Persson', email: 'anders@persson.se' },
//                         { namn: 'Anders Persson', email: 'anders@persson.se' },
//                         { namn: 'Anders Persson', email: 'anders@persson.se' },
//                         { namn: 'Anders Persson', email: 'anders@persson.se' },
//                         { namn: 'Anders Persson', email: 'anders@persson.se' },
//                     ]} columns={[new Column('Namn', 'namn', ColumnType.Text, null)]} onChange={() => {}} closableColumns={false}/>
//                 </Container>
//             </div>
//         </div>
//     </div>
// }