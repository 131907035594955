import {Course, Group} from "../../general/general";
import {useState} from "react";
import {useForm} from "@mantine/form";
import {
    ActionIcon,
    Button,
    Container,
    Grid,
    Group as GroupComponent,
    Select,
    Text,
    Textarea,
    TextInput
} from "@mantine/core";
import {IconX} from "@tabler/icons";
import {DatePicker} from "@mantine/dates";
import {useRecoilValue} from "recoil";
import {programmesState} from "../../state";

interface CreateProps {
    onCreate(value: Group): void;
}

export function CreateGroupView({ onCreate }: CreateProps) {
    const programmes = useRecoilValue(programmesState);
    const form = useForm({
        initialValues: {
            name: '',
            yhnr: '',
            programme: ''
        },
        validate: {
            name: (value) => value.trim().length < 2,
        }
    });

    const handleSubmit = (values: any) => {
        form.reset();
        onCreate(values);
        // setCourses([...courses, new Course(values.name, values.points, values.startDate, values.endDate)]);
    }

    const programmeData = programmes.map(programme => {return { value: programme.name, label: programme.name }});

    return <>
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Grid>
                <Grid.Col span={4}>
                    <TextInput withAsterisk label="Namn" placeholder="AABBCC" name="name" size="xs" {...form.getInputProps('name')}/>
                </Grid.Col>
                <Grid.Col span={2}>
                    <TextInput withAsterisk label="Yh Nr" placeholder="20" name="points" size="xs" {...form.getInputProps('yhnr')}/>
                </Grid.Col>
                <Grid.Col span={4}>
                    <Select withAsterisk data={programmeData} label="Utbildning" placeholder="Javautvecklare" name="programme" size="xs" {...form.getInputProps('programme')}/>
                </Grid.Col>
            </Grid>

            <Button type="submit" sx={{ marginTop: '10px' }} size="xs">Lägg till</Button>
        </form>

        <GroupComponent position="center" mt="xl">
            <Button size="sm" >
                Spara
            </Button>
        </GroupComponent>
    </>
}

interface EditProps {
    onCreate(value: Group): void;
    defaultValue: Group
}

export function EditGroupView({ onCreate, defaultValue }: EditProps) {
    const [group, setGroup] = useState<Group>(defaultValue);
    const form = useForm({
        initialValues: {
            name: '',
            startDate: '',
            endDate: '',
            points: 0
        },
        validate: {
            name: (value) => value.trim().length < 2,
        }
    });

    const handleSubmit = (values: any) => {
        form.reset();
        onCreate(values);
        // setCourse(new Course(values.name, values.points, values.startDate, values.endDate));
    }

    return <Container sx={{ overflow: 'auto', maxHeight: '90%', padding: '0px 10px 0px 0px' }}>
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Grid>
                <Grid.Col span={4}>
                    <TextInput withAsterisk label="Namn" placeholder="Avancerad Java" name="name" size="xs" {...form.getInputProps('name')}/>
                </Grid.Col>
                <Grid.Col span={2}>
                    <TextInput withAsterisk label="Poäng" placeholder="20" name="points" size="xs" {...form.getInputProps('points')}/>
                </Grid.Col>
                <Grid.Col span={3}>
                    <DatePicker withAsterisk label="Start Datum" name="startDate" size="xs" {...form.getInputProps('startDate')}/>
                </Grid.Col>
                <Grid.Col span={3}>
                    <DatePicker withAsterisk label="Slut Datum" name="endDate" size="xs" {...form.getInputProps('endDate')}/>
                </Grid.Col>
            </Grid>

            <Grid>
                <Grid.Col span={4}>
                    <TextInput withAsterisk label="Språk" placeholder="Svenska, Engelska" name="language" size="xs" {...form.getInputProps('language')}/>
                </Grid.Col>
                <Grid.Col span={2}>
                    <TextInput withAsterisk label="Version" placeholder="2" name="version" size="xs" {...form.getInputProps('version')}/>
                </Grid.Col>
                <Grid.Col span={3}>
                    <DatePicker withAsterisk label="Framtagen av" name="createdBy" size="xs" {...form.getInputProps('createdBy')}/>
                </Grid.Col>
                <Grid.Col span={3}>
                    <DatePicker withAsterisk label="Fastställd av" name="confirmedBy" size="xs" {...form.getInputProps('confirmedBy')}/>
                </Grid.Col>
            </Grid>

            <Textarea withAsterisk label="Kursmål" name=" " size="xs" {...form.getInputProps('language')}/>

            <Textarea withAsterisk label="Lärandemål (flera delar ADD IT YO)" name=" " size="xs" {...form.getInputProps('language')}/>
            <Textarea withAsterisk label="Lärandemål kunskaper" name=" " size="xs" {...form.getInputProps('language')}/>
            <Textarea withAsterisk label="Lärandemål färdigheter" name=" " size="xs" {...form.getInputProps('language')}/>
            <Textarea withAsterisk label="Lärandemål kompetenser" name=" " size="xs" {...form.getInputProps('language')}/>

            <TextInput withAsterisk label="Kunskapskontroller (flera delar)" placeholder="Svenska, Engelska" name="language" size="xs" {...form.getInputProps('language')}/>
            <TextInput withAsterisk label="Betygskala" placeholder="IG, G, VG" name="language" size="xs" {...form.getInputProps('language')}/>

            <Textarea withAsterisk label="För godkänt" name=" " size="xs" {...form.getInputProps('language')}/>
            <Textarea withAsterisk label="För väl godkänt" name=" " size="xs" {...form.getInputProps('language')}/>
            <Textarea withAsterisk label="Kommentar" name="comment" size="xs" {...form.getInputProps('comment')}/>
        </form>

        <GroupComponent position="center" mt="xl">
            <Button size="sm" >
                Spara
            </Button>
        </GroupComponent>
    </Container>
}