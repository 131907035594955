import {useRecoilState, useRecoilValue} from "recoil";
import {groupsState, programmesState} from "../state";
import {useNavigate, useParams} from "react-router-dom";
import {Accordion, Button, Container, createStyles, Divider, Drawer, Text, Timeline, Title} from "@mantine/core";
import {IconCircleCheck, IconCircleDashed, IconCircleDot} from "@tabler/icons";
import {useState} from "react";
import {EditCourseView, CreateCourseView} from "./components/CourseDrawer";
import {compareDates, getWeek} from "../utility";
import {TableScrollArea} from "../components/table/TableSticky";
import {Column, ColumnType, Course, Group, Programme} from "../general/general";

/*

courses, yhnr, name, class list?

 */

const useStyles = createStyles((theme, _, getRef) => ({
    main: {
        overflow: 'hidden',
        maxHeight: '100vh'
    },

    container: {
        height: '100%',
        width: '100%',
        margin: 'auto',
        marginTop: '20px',
        overflow: 'scroll',
    },

    contentContainer: {
        margin: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },

    header: {
        width: '100%',
        height: '70px',
        borderBottom: '1px solid #e9ecef',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },

    coursesFullContainer: {
        height: '30px',
        display: 'flex',
        // flexDirection: 'row',
    },

    coursesContainer: {
        width: '50%',
    },

    coursesContainerEmpty: {
        width: '50%',
    },

    weekContainer: {
        width: '50%',
        height: '30px',
        display: 'flex',
        flexDirection: 'row',
    },

    weekBox: {
        width: '30px',
        height: '30px',
        fontSize: '15px',
        lineHeight: '30px',
        backgroundColor: 'lightgreen',
        textAlign: 'center',
        borderRight: '1px solid black',
    },

    weekCourseBox: {
        width: '30px',
        height: '30px',
        textAlign: 'center',
        fontSize: '15px',
        lineHeight: '30px',
        // backgroundColor: 'lightcyan',
        borderBottom: '1px solid black',
        borderRight: '1px solid black',
    },

    completed: {
        backgroundColor: 'lightcyan',
    },
}));

function prettyDate(date: Date): string {
    return date.getFullYear() + "-" + ("0"+(date.getMonth()+1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
}

/*

Kurser

Accordion where you can open the courses


 */

function CourseView({ programme, group }: { programme: Programme, group: Group }) {
    const { cx, classes } = useStyles();

    let currentWeek = getWeek(new Date());

    const createLeaps = (): Course[][] => {
        let list: Course[][] = [
            [],
            []
        ];

        for (let course of programme.courses) {
            if (getWeek(course.endDate) <= 26) {
                list[0].push(course);
            } else {
                list[1].push(course);
            }
        }

        return list;
    }

    const getWeeks = (leap: number): number[] => {
        let list = [];
        for (let i = 26 * leap + 1; i <= (26 * (leap + 1)); i++) {
            list.push(i);
        }
        return list;
    }

    const leap = (): any => {
        let leaps = createLeaps();

        return leaps.map((frame, index) => {
            let weeks = getWeeks(index);
            return <div key={index}>

                <div className={cx(classes.coursesFullContainer)}>
                    <div className={cx(classes.coursesContainerEmpty)}></div>
                    <div className={cx(classes.weekContainer)}>
                        {weeks.map((week, weekIndex) => {
                            return <div key={weekIndex} className={cx(classes.weekBox)}>{week}</div>
                        })}
                    </div>
                </div>

                {frame.map((course, courseIndex) => {
                    return <div key={courseIndex} className={cx(classes.coursesFullContainer)}>
                        <div className={cx(classes.coursesContainer)}>
                            {course.name} | {course.points} | {course.startDate.getDay()}/{course.startDate.getMonth()}/{course.startDate.getFullYear()} - {course.endDate.getDay()}/{course.endDate.getMonth()}/{course.endDate.getFullYear()}
                        </div>
                        <div className={cx(classes.weekContainer)}>
                            {weeks.map((week, weekIndex) => {
                                let start = getWeek(course.startDate);
                                let end = getWeek(course.endDate);
                                let is = week >= start && week < end;
                                return <div key={weekIndex} className={cx(classes.weekCourseBox, { [classes.completed]: is && (currentWeek > week) })}>{is ? 16 : ''}</div>
                            })}
                        </div>
                    </div>
                })}

                <Divider sx={{ marginTop: '7px', marginBottom: '7px' }}/>
            </div>
        });
    }

    return <>
        {leap()}
    </>
}

enum ActiveDrawer {
    None,
    CreateCourse,
    EditCourse
}

export default function GroupView() {
    const { cx, classes } = useStyles();
    const { id } = useParams();
    const navigate = useNavigate();

    // const [activeDrawer, setActiveDrawer] = useState<ActiveDrawer>(ActiveDrawer.None);
    // const [editCourse, setEditCourse] = useState<Course | null>(null);
    const groups = useRecoilValue(groupsState);
    const programmes = useRecoilValue(programmesState);
    const [accordion, setAccordion] = useState<string[]>([]);
    const group: Group = groups.find(all => all.yhnr === id) as Group;
    const programme: Programme = programmes.find(all => all.name === (group.programme || '')) as Programme;


    if (group === undefined) {
        return <>Något gick snett.</>
    }

    const now = new Date();

    const data = [
        { namn: 'Anders Persson', email: 'anders@persson.se', telefon: '077-423 37 46' },
        { namn: 'Jakob Andersson', email: 'jabok@andersson.se', telefon: '070-423 37 57' },
        { namn: 'Susan Fredriksson', email: 'susan@fredriksson.se', telefon: '070-423 37 12' },
        { namn: 'Gustav Dill', email: 'gustav@dill.se', telefon: '070-429 78 32' },
        { namn: 'Fredrik Ustev', email: 'fredrik@ustev.se', telefon: '074-113 37 98' },
        { namn: 'Ulf Göran', email: 'ulf@göran.se', telefon: '070-913 37 83' },
        { namn: 'Anna Isaksson', email: 'anna@isaksson.se', telefon: '070-283 37 82' },
        { namn: 'Per Staf', email: 'per@staf.se', telefon: '070-533 37 51' },
        { namn: 'Olof Isac', email: 'olof@isac.se', telefon: '079-425 35 65' },
        { namn: 'Andreas Högstedt', email: 'andreas@högstedt.se', telefon: '070-423 37 37' },
        { namn: 'Vera Persson', email: 'vera@persson.se', telefon: '070-823 17 91' },
        { namn: 'Kim Bill', email: 'kim@bill.se', telefon: '071-433 37 19' },
    ]

    const columns = [
        new Column('Namn', 'namn', ColumnType.Text, [], false),
        new Column('Email', 'email', ColumnType.Text, [], false),
        new Column('Telefon', 'telefon', ColumnType.Text, [], false),
    ];

    return <div className={cx(classes.main)}>
        {/*<Drawer opened={activeDrawer === ActiveDrawer.CreateCourse} onClose={() => setActiveDrawer(ActiveDrawer.None)} title={"Lägg till kursplaner"} size="xxl" padding="xl">*/}
        {/*    <CreateCourseView onCreate={onCreateCourses}/>*/}
        {/*</Drawer>*/}
        {/*<Drawer opened={activeDrawer === ActiveDrawer.EditCourse}*/}
        {/*        onClose={() => setActiveDrawer(ActiveDrawer.None)}*/}
        {/*        title={"Redigera kursplan"}*/}
        {/*        size="xxl"*/}
        {/*        padding="xl">*/}
        {/*    {editCourse && <EditCourseView onCreate={onEditCourse} defaultValue={editCourse} />}*/}
        {/*</Drawer>*/}

        {/*<div className={cx(classes.header)}>*/}
        {/*    <Button sx={{ marginLeft: '6px' }} variant='outline' onClick={() => navigate('/planering')}>Tillbaka</Button>*/}
        {/*    <Button sx={{ marginLeft: 'auto', marginRight: '6px', }} variant='outline' onClick={() => setActiveDrawer(ActiveDrawer.CreateCourse)}>Ny kursplan</Button>*/}
        {/*</div>*/}

        <div className={cx(classes.container)}>
            <Container>
                <Title align="center">{group.name}</Title>
            </Container>

            <Divider sx={{margin: '10px' }} />

            <Accordion value={accordion} sx={{ width: '100%' }} multiple={true} variant='separated' onChange={setAccordion}>
                <Accordion.Item value="courses">
                    <Accordion.Control ><Text>Kurser</Text></Accordion.Control>
                    <Accordion.Panel>

                        <CourseView programme={programme} group={group} />

                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="people">
                    <Accordion.Control><Text>Studerande</Text></Accordion.Control>
                    <Accordion.Panel>
                        <TableScrollArea data={data} columns={columns} onChange={() => {}} closableColumns={false} />
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        </div>
    </div>
}