import {Container, createStyles, Header, Button} from "@mantine/core";

const useStyles = createStyles((theme, _, getRef) => ({
    container: {

    },

    header: {
        width: '100%',
        height: '70px',
        borderBottom: '1px solid #e9ecef',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },

    classCard: {
        borderBottom: '1px solid #e9ecef',
        padding: '5px',
    }
}));

/*

Skapa ny utbildning
Skapa ny kurs


 */

export default function ClassView() {
    const {cx, classes } = useStyles();

    let data = [
        { name: 'Javautvecklare', completed: 0.8, courses: ['Java', 'Avancerad Java', 'JavaScript', 'Databaser', 'Testdriven Utveckling'] },
        { name: 'Javautvecklare', completed: 0.8, courses: ['Java', 'Avancerad Java', 'JavaScript', 'Databaser', 'Testdriven Utveckling'] },
        { name: 'Javautvecklare', completed: 0.8, courses: ['Java', 'Avancerad Java', 'JavaScript', 'Databaser', 'Testdriven Utveckling'] },
        { name: 'Javautvecklare', completed: 0.8, courses: ['Java', 'Avancerad Java', 'JavaScript', 'Databaser', 'Testdriven Utveckling'] },
    ];

    return <div className={cx(classes.container)}>
        <div className={cx(classes.header)}>
            <Button sx={{ marginRight: '10px' }} variant='outline'>Ny Klass</Button>
        </div>

        <div>
            {data.map(clazz => {
                return <div className={cx(classes.classCard)}>
                    <span>{clazz.name}</span>
                    <span>JUV21</span>
                    <span>{(clazz.completed * 100).toFixed(0)}%</span>
                    <div>

                    </div>
                </div>
            })}
        </div>
    </div>
}