export function compareDates(a: Date, b: Date): boolean {
    return a.getTime() >= b.getTime();
}

export function getWeek(date: Date) {

    const calc = function(o: any) {
        if(o.dtmin.getDay()!=1){
            if(o.dtmin.getDay()<=4 && o.dtmin.getDay()!=0)o.w+=1;
            o.dtmin.setDate((o.dtmin.getDay()==0)? 2 : 1+(7-o.dtmin.getDay())+1);
        }
        o.w+=Math.ceil((((o.dtmax.getTime()-o.dtmin.getTime())/(24*60*60*1000))+1)/7);
    }

    const getNbDaysInAMonth = function(year: number, month: number): any {
        let nbdays=31;
        let dtInst;
        for(let i=0;i<=3;i++){
            nbdays=nbdays-i;
            if((dtInst=new Date(year,month-1,nbdays)) && dtInst.getDate()==nbdays && (dtInst.getMonth()+1)==month  && dtInst.getFullYear()==year)
                break;
        }
        return nbdays;
    };
    if(date.getMonth()+1==1 && date.getDate()>=1 && date.getDate()<=3 && (date.getDay()>=5 || date.getDay()==0)){
        var pyData={"dtmin":new Date(date.getFullYear()-1,0,1,0,0,0,0),"dtmax":new Date(date.getFullYear()-1,11,getNbDaysInAMonth(date.getFullYear()-1,12),0,0,0,0),"w":0};
        calc(pyData);
        return pyData.w;
    }else{
        var ayData={"dtmin":new Date(date.getFullYear(),0,1,0,0,0,0),"dtmax":new Date(date.getFullYear(),date.getMonth(),date.getDate(),0,0,0,0),"w":0},
            nd12m=getNbDaysInAMonth(date.getFullYear(),12);
        if(date.getMonth()==12 && date.getDay()!=0 && date.getDay()<=3 && nd12m-date.getDate()<=3-date.getDay())ayData.w=1;else calc(ayData);
        return ayData.w;
    }
}