import React from 'react';
import {MantineProvider} from "@mantine/core";
import {RecoilRoot, useRecoilState} from "recoil";
import {loginState} from "./state";
import Main from "./Main";
import Login from "./Login";

function Content() {
    const [login, setLogin] = useRecoilState(loginState);

    return <>{login ? <Main /> : <Login />}</>
}

/*



Separate logic from UI? Have systems that are imported into components somehow?

Fler kolumntyper
Redigering av studerande (i tabell)

Kolumner per klass och globalt
Delmoment på kurse
Studerande (tags?)
Specifik studerande vy
Varje kolumn kan ha flera typer av kolumner
Kommentar kolumntyp (TableRowString men med förstorbar textfält)

Statistik per kurs i tabell där det står hur många som klarat kurser

Ledningsgrupp på utbildningsnivå
Kopiera ledningsgrupp
Dra för att definiera veckovis schema

Kolumn konfiguration per klass, globalt och per användare
Kolumn ordning konfiguration per klass, global och per användare

Utbildningsplan

Ledningsgrupp direkt när man skapar utbildning
Användardatabas
Kolumner på ledningsgrupp också

Klass:
yh nummer också
Kom ihåg lista kopplade till datum (varna innan)
Kunskapsmål här också? (lärandemål)

vilket kurslärandemål tillhör vilket utbildingslärandemål

delmål kolumn

Gröna betyg om betyg knapp är på i tabell


Slutbetug och lärandemål per person i tabell VIKTIGT VIKTIGT VIKTIGT

 */

function App() {
    return (
        <RecoilRoot>
            <MantineProvider withGlobalStyles withNormalizeCSS>
                <Content />
            </MantineProvider>
        </RecoilRoot>
    )
}

export default App;
