import { useState } from 'react';
import { Navbar, Center, Tooltip, UnstyledButton, createStyles, Stack } from '@mantine/core';
import {
    TablerIcon,
    IconHome2,
    IconUser,
    IconSettings,
    IconLogout,
    IconSwitchHorizontal, IconTable, IconAperture, IconTableOptions, IconSchema, IconListDetails,
} from '@tabler/icons';
import {useNavigate} from "react-router-dom";

const useStyles = createStyles((theme) => ({
    link: {
        width: 50,
        height: 50,
        borderRadius: theme.radius.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
        },
    },

    active: {
        '&, &:hover': {
            backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
            color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
        },
    },
}));

interface NavbarLinkProps {
    icon: TablerIcon;
    label: string;
    active?: boolean;
    onClick?(): void;
}

function NavbarLink({ icon: Icon, label, active, onClick }: NavbarLinkProps) {
    const { classes, cx } = useStyles();
    return (
        <Tooltip label={label} position="right" transitionDuration={0}>
    <UnstyledButton onClick={onClick} className={cx(classes.link, { [classes.active]: active })}>
    <Icon stroke={1.5} />
    </UnstyledButton>
    </Tooltip>
);
}

const mockdata = [
    // { icon: IconHome2, label: 'Hem', path: '/' },
    { icon: IconTable, label: 'Tabell', path: '/tabell' },
    // { icon: IconListDetails, label: 'Klasser', path: '/klasser' },
    { icon: IconSchema, label: 'Planering', path: '/planering' },
    { icon: IconTableOptions, label: 'Konfigurering', path: '/konfiguration' },
    // { icon: IconSettings, label: 'Inställningar', path: '/inställningar' },
    { icon: IconUser, label: 'Konto', path: '/konto' },
];

export function NavbarMinimal() {
    const [active, setActive] = useState(0);
    const navigate = useNavigate();

    const links = mockdata.map((link, index) => (
        <NavbarLink
            {...link}
            key={link.label}
            active={index === active}
            onClick={() => {
                setActive(index);
                navigate(link.path)
            }}
        />
    ));

    return (
        <Navbar width={{base: 80}} p="md">
            <Center>
                <IconAperture width="30px" height="30px" color="black"/>
            </Center>
            <Navbar.Section grow mt={50}>
                <Stack justify="center" spacing={0}>
                    {links}
                </Stack>
            </Navbar.Section>
            <Navbar.Section>
                <Stack justify="center" spacing={0}>
                    <NavbarLink icon={IconSwitchHorizontal} label="Byt konto"/>
                    <NavbarLink icon={IconLogout} label="Logga ut"/>
                </Stack>
            </Navbar.Section>
        </Navbar>
    );
}