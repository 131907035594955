/*

?
Permissions?
Admin stuff?

 */

export function SettingsView() {
    return <>
        Inställningar
    </>
}

/*

Hej! Det kom up en grej som jag skulle behöva göra så vi får skippa mötet. Jag skriver ned saker som jag kommer att göra så får du väl fylla på.


* Tabell med studerande
* Tabell konfiguration (ta bort kolumner tillfälligt, ta bort kolumner permanent, lägga till kolumner med data o.s.v)
* Skapa, ta bort och modifiera studerande
* Skapa, ta bort och modifiera konfiguration grejer
* Tillfällig profil sida där man kan se lite tillfällig hårdkodad information
* Inställningar (dock osäker på vad än så länge)
* Hem sida (osäker på vad den skall innehålla)

 */

