import {atom} from "recoil";
import {Column, ColumnCategory, ColumnType, Profile} from "./general/general";

export const loginState = atom({
    key: 'loginState',
    default: true,
});

let categories = [
    new ColumnCategory('Profil'),
];

export const columnCategoryState = atom({
    key: 'columnCategories',
    default: categories,
});

export const tableColumns = atom({
    key: 'tableColumns',
    default: [
        new Column('Namn', 'namn', ColumnType.Text, [categories[0]], false),
        new Column('Email', 'email', ColumnType.Text, [categories[0]], false),
        new Column('Ort', 'ort', ColumnType.Text, [categories[0]], false),
        new Column('Telefon', 'telefon', ColumnType.Text, [categories[0]], false),
        new Column('Utbildning', 'utbildning', ColumnType.TextList, [categories[0]], false),
        new Column('Kommentar', 'kommentar', ColumnType.Comment, [categories[0]], false),
    ],
});

export const studentData = atom({
    key: 'studentData',
    default: [
        { namn: 'Anders Persson', email: 'anders@persson.se', betyg: 'IG', ort: 'Malmö', telefon: '077-423 37 46', utbildning: ['Javautvecklare'], 'Java': [{ 0: 'G' }, { 1: 'G' }, { 2: 'G' }] },
        { namn: 'Jakob Andersson', email: 'jabok@andersson.se', betyg: 'IG', ort: 'Malmö', telefon: '070-423 37 57', utbildning: ['Javautvecklare'], 'Avancerad JavaScript': [{ 0: 'G' }, { 1: 'G' }, { 2: 'G' }] },
        { namn: 'Susan Fredriksson', email: 'susan@fredriksson.se', betyg: 'IG',ort: 'Malmö', telefon: '070-423 37 12', utbildning: ['JavaScript utvecklare'] },
        { namn: 'Gustav Dill', email: 'gustav@dill.se', betyg: 'IG',ort: 'Malmö', telefon: '070-429 78 32', utbildning: ['DevOps'] },
        { namn: 'Fredrik Ustev', email: 'fredrik@ustev.se', betyg: 'IG',ort: 'Malmö', telefon: '074-113 37 98', utbildning: ['Webbutvecklare'] },
        { namn: 'Ulf Göran', email: 'ulf@göran.se', betyg: 'IG',ort: 'Malmö', telefon: '070-913 37 83', utbildning: ['Webbutvecklare'] },
        { namn: 'Anna Isaksson', email: 'anna@isaksson.se', betyg: 'IG',ort: 'Malmö', telefon: '070-283 37 82', utbildning: ['Webbutvecklare'] },
        { namn: 'Per Staf', email: 'per@staf.se', betyg: 'IG',ort: 'Malmö', telefon: '070-533 37 51', utbildning: ['Webbutvecklare'] },
        { namn: 'Olof Isac', email: 'olof@isac.se', betyg: 'IG',ort: 'Malmö', telefon: '079-425 35 65', utbildning: ['Webbutvecklare'] },
        { namn: 'Andreas Högstedt', email: 'andreas@högstedt.se', betyg: 'IG',ort: 'Malmö', telefon: '070-423 37 37', utbildning: ['Webbutvecklare'] },
        { namn: 'Vera Persson', email: 'vera@persson.se', betyg: 'IG',ort: 'Malmö', telefon: '070-823 17 91', utbildning: ['Webbutvecklare'] },
        { namn: 'Kim Bill', email: 'kim@bill.se', betyg: 'IG',ort: 'Malmö', telefon: '071-433 37 19', utbildning: ['Webbutvecklare', 'Javautvecklare'] },
    ],
});

let courses = [
    { name: 'Java', startDate: new Date("2022-06-01"), endDate: new Date("2022-07-01"), points: 20 },
    { name: 'Avancerad Java', startDate: new Date("2022-07-01"), endDate: new Date("2022-08-01"), points: 30 },
    { name: 'JavaScript', startDate: new Date("2022-08-01"), endDate: new Date("2022-09-01"), points: 15 },
    { name: 'Avancerad JavaScript', startDate: new Date("2022-09-01"), endDate: new Date("2022-10-01"), points: 35 },
    { name: 'Databaser', startDate: new Date("2022-10-01"), endDate: new Date("2022-11-01"), points: 40 },
    { name: 'Testdriven Utveckling', startDate: new Date("2022-11-01"), endDate: new Date("2022-12-01"), points: 25 },
];

export const programmesState = atom({
    key: 'programmes',
    default: [
        { name: 'Javautvecklare', yhnr: 'java', courses: courses },
        { name: 'JavaScript utvecklare', yhnr: 'javascript', courses: courses },
        { name: 'DevOps', yhnr: 'devops', courses: courses },
        { name: 'Webbutvecklare', yhnr: 'webb', courses: courses },
        { name: 'System designer', yhnr: 'sys', courses: courses },
    ],
});

export const groupsState = atom({
    key: 'groups',
    default: [
        { name: 'EXJLOI21', yhnr: 'CD', programme: 'Javautvecklare', hours: 100, formOfStudy: 'Bunden', speedOfStudy: 0.5 },
        { name: 'ABELEI22', yhnr: 'CA', programme: 'Webbutvecklare', hours: 100, formOfStudy: 'Bunden', speedOfStudy: 0.5 },
        { name: 'LEAUOC20', yhnr: 'CB', programme: 'JavaScript utvecklare', hours: 100, formOfStudy: 'Bunden', speedOfStudy: 0.5 }
    ],
});

export const profileState = atom({
    key: 'profile',
    default: new Profile(),
});