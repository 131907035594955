import {ActionIcon, Button, Drawer, Text, Grid, Group, Select, SimpleGrid, Space, Textarea, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {programmesState, studentData} from "../../state";
import {DatePicker} from "@mantine/dates";
import {Course} from "../../general/general";
import {useState} from "react";
import {IconPlus} from "@tabler/icons";


/*

namn
yh nr
id nummer?
admin

 */

export default function ProgrammeDrawer({ opened, onClose }: { opened: boolean, onClose(): void }) {
    const [programmes, setProgrammes] = useRecoilState(programmesState);
    const [people, setPeople] = useState<string[]>(['']);
    const peopleState = useRecoilValue(studentData);

    const form = useForm({
        initialValues: {
            name: '',
            yhnr: '',
            confirmationYear: new Date().getFullYear()
        },
        validate: {
            name: (value) => value.trim().length < 2,
            yhnr: (value) => value.trim().length < 2,
            confirmationYear: (value: number) => {
                return Number.isNaN(value) && value > 0 && value < 3000;
            }
        }
    });

    const addPerson = () => {
        setPeople([...people, '']);
    }

    const editPerson = (index: number, value: any) => {
        setPeople(people.map((person: any, i) => {
            if (i !== index) return person;
            return value;
        }));
    }

    const handleSubmit = (values: any) => {
        setProgrammes([...programmes, { name: values.name, yhnr: values.yhnr, courses: [] }]);
        onClose();
    }

    const peopleData = peopleState.map(person => {
       return { value: person.namn, label: person.namn }
    });

    return <>
        <Drawer opened={opened} onClose={onClose} title={"Lägg till utbildning"} size="xl" padding="xl">
            <form onSubmit={form.onSubmit(handleSubmit)}>
                <TextInput withAsterisk label="Namn" placeholder="Javautvecklare" name="name" {...form.getInputProps('name')}/>
                <SimpleGrid cols={2} mt="xl" breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
                    <TextInput withAsterisk label="Beslut år" placeholder="2020" name="confirmationYear" {...form.getInputProps('confirmationYear')}/>
                    <TextInput withAsterisk label="YH. Nr" placeholder="abc123" name="yhnr" {...form.getInputProps('yhnr')}/>
                </SimpleGrid>

                <Space mt={15}/>
                <Text size={12}>Ledningsgrupp</Text>

                {people.map((person, index) => {
                    return <Grid key={index}>
                        <Grid.Col span={10}>
                            <Select value={person} onChange={value => editPerson(index, value)} data={peopleData}/>
                        </Grid.Col>
                        {
                            index === people.length - 1 && <Grid.Col span={1}>
                                <ActionIcon onClick={addPerson}>
                                    <IconPlus />
                                </ActionIcon>
                            </Grid.Col>
                        }
                    </Grid>
                })}
                <Space mt={15}/>

                <Textarea withAsterisk label="Kommentar" name="comment" size="xs" {...form.getInputProps('comment')}/>


                <Group position="center" mt="xl">
                    <Button type="submit" size="md">
                        Spara
                    </Button>
                </Group>
            </form>
        </Drawer>
    </>
}