import React, {ReactElement, useState} from 'react';
import { createStyles, Table, ScrollArea } from '@mantine/core';
import {IconX} from "@tabler/icons";
import {Column, getColumnElement} from "../../general/general";

const useStyles = createStyles((theme, _, getRef) => ({
    header: {
        position: 'sticky',
        top: 0,
        zIndex: 10,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
        transition: 'box-shadow 150ms ease',

        '&::after': {
            content: '""',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            borderBottom: `1px solid ${
                theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]
            }`,
        },
    },

    scrolled: {
        boxShadow: theme.shadows.sm,
    },

    columnClose: {
        ref: getRef('columnClose'),
        alignSelf: 'flex-end',
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        cursor: 'pointer',
        opacity: 0,
        color: 'red',

        '&:hover': {
            color: 'lightcoral',
        }
    },

    column: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        [`&:hover .${getRef('columnClose')}`]: {
            opacity: 100,
        }
    },

    actions: {
        width: '1px'
    },

    action: {
        marginRight: '7px',
    },

    actionRow: {
        display: 'flex',
        justifyContent: 'flex-start',
    }
}));

export enum TableAction {
    None,
    EditRow,
    SaveRow
}

interface ActionObject {
    element: ReactElement;
    handler(i: number): TableAction;
}

interface TableScrollAreaProps {
    data: any[];
    columns: Column[];
    closableColumns?: boolean,
    actions?: ActionObject[],
    editMode?: boolean;
    onChange(key: string, value: any, object: any, index: number): void;
    onClose?(column: Column): void;
}

export function TableScrollArea({ data, columns, closableColumns = false, onChange, onClose = _=>{}, actions, editMode = false }: TableScrollAreaProps) {
    const { classes, cx } = useStyles();
    const [scrolled, setScrolled] = useState(false);
    const [editing, setEditing] = useState<number[]>([]);
    const [pressed, setPressed] = useState<Column[]>([]);

    const handleAction = (action: TableAction, rowIndex: number) => {
        switch (action) {
            case TableAction.EditRow:
                setEditing([...editing, rowIndex]);
                break;
            case TableAction.SaveRow:
                setEditing(editing.filter(all => all !== rowIndex));
                break;
            case TableAction.None:
            default:
                break;
        }
    }

    const rows = data.map((row: any, rowIndex) => (
        <tr key={rowIndex}>
            {columns.map((column, columnIndex) => {
                const Row = getColumnElement(column);
                let configOnChange = (value: any) => {
                    onChange(column.key, value, row, rowIndex);
                }

                return <td key={columnIndex}>
                    <Row value={row[column.key]} pressed={pressed.includes(column)} onChange={configOnChange} editing={editing.includes(rowIndex) || editMode}/>
                </td>;
            })}

            {actions && <td className={cx(classes.actionRow)}>{actions.map((action, index) => {
                return <span className={cx(classes.action)} onClick={() => {
                    handleAction(action.handler(rowIndex), rowIndex);
                }} key={index}>{action.element}</span>
            })}</td>}
        </tr>
    ));

    return (
        <ScrollArea sx={{ maxHeight: '80vh', maxWidth: '80vw' }} onScrollPositionChange={({ y }) => setScrolled(y !== 0)}>
            <Table sx={{ maxWidth: '80vw' }}>
                <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
                <tr>
                    {columns.map((column, index) => (
                        <th key={index}>
                            <div className={cx(classes.column)}>
                                <span onClick={() => {
                                    if (!column.extendable) return;
                                    if (pressed.includes(column)) {
                                        setPressed(pressed.filter(all => all !== column));
                                    } else {
                                        setPressed([...pressed, column]);
                                    }
                                }}>{column.name}</span>
                                {column.extendable && <span>+</span>}
                                {closableColumns && <span onClick={() => onClose(column)} className={cx(classes.columnClose)}>
                                    <IconX width={22} height={22}/>
                                </span>}
                            </div>
                        </th>
                    ))}
                    {actions && <th className={cx(classes.actions)}>Åtgärd</th>}
                </tr>
                </thead>
                <tbody>{rows}</tbody>
            </Table>
        </ScrollArea>
    );
}