import {Container, createStyles, Header, Button, Accordion, Text, Divider, Drawer} from "@mantine/core";
import ProgrammeDrawer from "./components/ProgrammeDrawer";
import {useState} from "react";
import {useRecoilValue} from "recoil";
import {groupsState, programmesState} from "../state";
import {useNavigate} from "react-router-dom";
import {AccordionValue} from "@mantine/core/lib/Accordion/Accordion.types";
import {CreateCourseView, EditCourseView} from "./components/CourseDrawer";
import {CreateGroupView} from "./components/Groups";

const useStyles = createStyles((theme, _, getRef) => ({
    container: {

    },

    header: {
        width: '100%',
        height: '70px',
        borderBottom: '1px solid #e9ecef',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },

    contentContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    },

    programmesContainer: {
        display: 'flex',
    },

    groupsContainer: {
        display: 'flex',
    },

    programmeContainer: {
        width: '200px',
        height: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #e9ecef',
        borderRadius: 6,
        margin: '10px',
        cursor: 'pointer',

        '&:hover': {
            border: '2px solid #e9ecef',
        }
    }
}));

/*

Skapa ny utbildning
Skapa ny kurs


 */

/*
YH nr?
ort,

 */

enum ActiveDrawer {
    None,
    CreateProgramme,
    CreateGroup
}

export default function PlanningView() {
    const navigate = useNavigate();
    const {cx, classes } = useStyles();
    const [activeDrawer, setActiveDrawer] = useState<ActiveDrawer>(ActiveDrawer.None);
    const [accordion, setAccordion] = useState<string[]>([]);
    const programmes = useRecoilValue(programmesState);
    const groups = useRecoilValue(groupsState);

    return <div className={cx(classes.container)}>
        <ProgrammeDrawer opened={activeDrawer === ActiveDrawer.CreateProgramme} onClose={() => setActiveDrawer(ActiveDrawer.None)}/>
        <Drawer opened={activeDrawer === ActiveDrawer.CreateGroup} onClose={() => setActiveDrawer(ActiveDrawer.None)} title={"Lägg till klass"} size="xl" padding="xl">
            <CreateGroupView onCreate={() => {}}/>
        </Drawer>

        <div className={cx(classes.header)}>
            {/*<Button sx={{ marginRight: '6px' }} variant='outline' onClick={() => {}}>Kopiera utbildning</Button>*/}
            {/*<Button variant='outline' onClick={() => {}}>Kopiera klass/intag</Button>*/}
            {/*<Divider orientation="vertical" sx={{ margin: '6px' }}/>*/}
            <Button sx={{ marginRight: '6px' }} variant='outline' onClick={() => setActiveDrawer(ActiveDrawer.CreateProgramme)}>Ny utbildning</Button>
            <Button sx={{ marginRight: '6px' }} variant='outline' onClick={() => setActiveDrawer(ActiveDrawer.CreateGroup)}>Ny klass/intag</Button>
        </div>

        <div className={cx(classes.contentContainer)}>
            <Accordion value={accordion} sx={{ width: '100%' }} multiple={true} variant='separated' onChange={setAccordion}>
                <Accordion.Item value="programmes">
                    <Accordion.Control ><Text>Utbildningar</Text></Accordion.Control>
                    <Accordion.Panel>
                        <div className={cx(classes.programmesContainer)}>
                            {programmes.map(programme => {
                                return <div key={programme.name}
                                            className={cx(classes.programmeContainer)}
                                            onClick={() => navigate('/utbildning/' + programme.yhnr)}
                                >{programme.name}</div>
                            })}
                        </div>
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="groups">
                    <Accordion.Control><Text>Klasser/Intag</Text></Accordion.Control>
                    <Accordion.Panel>
                        <div className={cx(classes.groupsContainer)}>
                            {groups.map(group => {
                                return <div key={group.name}
                                            className={cx(classes.programmeContainer)}
                                            onClick={() => navigate('/intag/' + group.yhnr)}
                                >{group.name}</div>
                            })}
                        </div>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            {/*{programmes.map(programme => {*/}
            {/*    return <div key={programme.name}*/}
            {/*                className={cx(classes.programmeContainer)}*/}
            {/*                onClick={() => navigate('/utbildning/' + programme.yhnr)}*/}
            {/*    >{programme.name}</div>*/}
            {/*})}*/}
        </div>
    </div>
}