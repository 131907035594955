import {
    ActionIcon,
    Button,
    Drawer,
    Group,
    SimpleGrid,
    TextInput,
    Text,
    Grid,
    Textarea,
    Container,
    Select
} from "@mantine/core";
import {useForm} from "@mantine/form";
import {useRecoilState, useSetRecoilState} from "recoil";
import {programmesState} from "../../state";
import {DatePicker} from "@mantine/dates";
import {useState} from "react";
import {Course} from "../../general/general";
import {IconCross, IconPlus, IconSquarePlus, IconX} from "@tabler/icons";
import {InputLabel} from "@mantine/core/lib/Input/InputLabel/InputLabel";


/*

namn
yh nr
id nummer?
admin

 */

export interface CreateCourseProps {
    onCreate(courses: Course[]): void;
}

function ListItems({ onCreate }: { onCreate(values: { type: string, content: string }[]): void }) {
    const [items, setItems] = useState<{ type: string, content: string }[]>([ { type: 'knowledge', content: ''}]);

    const editType = (index: number, value: string) => {
        setItems(items.map((item,i ) => {
            if (i !== index) return item;

            return {...item, type: value };
        }));
    }

    const editContent = (index: number, value: string) => {
        setItems(items.map((item,i ) => {
            if (i !== index) return item;

            return {...item, content: value };
        }));
    }

    const add = () => {
        setItems([...items, { type: 'knowledge', content: '' }]);
    }

    return <>
        <label>Lärandemål</label>

        {items.map((item, index) => {
            return <div key={index}>
                <Grid >
                    <Grid.Col span={3}>
                        <Select
                            size="xs"
                            data={[
                                { value: 'knowledge', label: 'Kunskap' },
                                { value: 'skills', label: 'Färdighet' },
                                { value: 'competences', label: 'Kompetens' },
                            ]}
                            value={item.type}
                            onChange={value => editType(index, value || 'knowledge')}
                        />
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <TextInput size="xs" value={item.content} onChange={event => editContent(index, event.target.value)} />
                    </Grid.Col>
                    {
                        index === items.length - 1 && <Grid.Col span={1}>
                            <ActionIcon onClick={add}>
                                <IconPlus />
                            </ActionIcon>
                        </Grid.Col>
                    }
                </Grid>
            </div>
        })}
    </>
}

export function CreateCourseView({ onCreate }: CreateCourseProps) {
    const [courses, setCourses] = useState<Course[]>([new Course('', 0, new Date(), new Date())]);
    const handleSubmit = () => {
        let a = courses.map((course: any) => {
            return { ...course, points: Number.parseInt(course.points) };
        });
        onCreate(a);
    }

    const add = () => {
        setCourses([...courses, new Course('', 0, new Date(), new Date())]);
    }

    const edit = (index: number, key: string, value: any) => {
        setCourses(courses.map((course: any, i) => {
            if (i !== index) return course;

            let copy = { ...course };
            copy[key] = value;
            return copy;
        }));
    }

    return <>
        <div>
            {courses.map((course, index) => {
                return <div key={index}>
                    <Grid>
                        <Grid.Col span={4}>
                            <TextInput withAsterisk label={index === 0 ? "Namn": ""} placeholder="Avancerad Java" name="name" size="xs" value={course.name} onChange={event => edit(index, 'name', event.target.value)}/>
                        </Grid.Col>
                        <Grid.Col span={2}>
                            <TextInput withAsterisk label={index === 0 ? "Poäng": ""} placeholder="20" name="points" size="xs" value={course.points} onChange={event => edit(index, 'points', Number.parseInt(event.target.value))}/>
                        </Grid.Col>
                        <Grid.Col span={3}>
                            <DatePicker withAsterisk label={index === 0 ? "Start Datum": ""} name="startDate" size="xs" value={course.startDate} onChange={value => edit(index, 'startDate', value)}/>
                        </Grid.Col>
                        <Grid.Col span={3}>
                            <DatePicker withAsterisk label={index === 0 ? "Slut Datum": ""} name="endDate" size="xs" value={course.endDate} onChange={value => edit(index, 'endDate', value)}/>
                        </Grid.Col>
                    </Grid>
                </div>
            })}
        </div>

        <Button sx={{ marginTop: '10px' }} size="xs" onClick={add}>Lägg till</Button>

        <Group position="center" mt="xl">
            <Button size="sm" onClick={handleSubmit}>
                Spara
            </Button>
        </Group>
    </>
}

export interface EditCourseProps {
    onCreate(course: Course): void;
    defaultValue: Course
}

export function EditCourseView({ onCreate, defaultValue }: EditCourseProps) {
    const [course, setCourse] = useState<Course>(defaultValue);
    const [items, setItems] = useState<{ type: string, content: string }[]>([]);
    const form = useForm({
        initialValues: {
            name: course.name,
            startDate: course.startDate,
            endDate: course.endDate,
            points: course.points,
            languages: course.languages,
            version: course.version,
            createdBy: course.createdBy,
            confirmedBy: course.confirmedBy,
            grades: course.grades,
            goal: course.goal,
            requirementsG: course.requirementsG,
            requirementsVG: course.requirementsVG,
        },
        validate: {
            name: (value) => value.trim().length < 2,
        }
    });

    const handleSubmit = (values: any) => {
        form.reset();
        let knowledge = [];
        let skills = [];
        let competences = [];

        for (let item of items) {
            if (item.type === 'knowledge') knowledge.push(item.content);
            if (item.type === 'skills') skills.push(item.content);
            if (item.type === 'competences') competences.push(item.content);
        }

        let a = { ...values, knowledge, competences, skills };
        onCreate(a);
    }

    return <Container sx={{ overflow: 'auto', maxHeight: '90%', padding: '0px 10px 0px 0px' }}>
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Grid>
                <Grid.Col span={4}>
                    <TextInput withAsterisk label="Namn" placeholder="Avancerad Java" name="name" size="xs" {...form.getInputProps('name')}/>
                </Grid.Col>
                <Grid.Col span={2}>
                    <TextInput withAsterisk label="Poäng" placeholder="20" name="points" size="xs" {...form.getInputProps('points')}/>
                </Grid.Col>
                <Grid.Col span={3}>
                    <DatePicker withAsterisk label="Start Datum" name="startDate" size="xs" {...form.getInputProps('startDate')}/>
                </Grid.Col>
                <Grid.Col span={3}>
                    <DatePicker withAsterisk label="Slut Datum" name="endDate" size="xs" {...form.getInputProps('endDate')}/>
                </Grid.Col>
            </Grid>

            <Grid>
                <Grid.Col span={4}>
                    <TextInput withAsterisk label="Språk" placeholder="Svenska, Engelska" name="languages" size="xs" {...form.getInputProps('languages')}/>
                </Grid.Col>
                <Grid.Col span={2}>
                    <TextInput withAsterisk label="Version" placeholder="2" name="version" size="xs" {...form.getInputProps('version')}/>
                </Grid.Col>
                <Grid.Col span={3}>
                    <DatePicker withAsterisk label="Framtagen av" name="createdBy" size="xs" {...form.getInputProps('createdBy')}/>
                </Grid.Col>
                <Grid.Col span={3}>
                    <DatePicker withAsterisk label="Fastställd av" name="confirmedBy" size="xs" {...form.getInputProps('confirmedBy')}/>
                </Grid.Col>
            </Grid>

            <Textarea withAsterisk label="Kursmål" name="goal" size="xs" {...form.getInputProps('goal')}/>

            <ListItems onCreate={setItems} />

            {/*<Textarea withAsterisk label="Lärandemål (flera delar ADD IT YO)" name=" " size="xs" {...form.getInputProps('language')}/>*/}
            {/*<Textarea withAsterisk label="Lärandemål kunskaper" name=" " size="xs" {...form.getInputProps('language')}/>*/}
            {/*<Textarea withAsterisk label="Lärandemål färdigheter" name=" " size="xs" {...form.getInputProps('language')}/>*/}
            {/*<Textarea withAsterisk label="Lärandemål kompetenser" name=" " size="xs" {...form.getInputProps('language')}/>*/}

            {/*<TextInput withAsterisk label="Kunskapskontroller (flera delar)" placeholder="Svenska, Engelska" name="language" size="xs" {...form.getInputProps('language')}/>*/}
            <TextInput withAsterisk label="Betygskala" placeholder="IG, G, VG" name="grades" size="xs" {...form.getInputProps('grades')}/>

            <Textarea withAsterisk label="För godkänt" name="requirementsG" size="xs" {...form.getInputProps('requirementsG')}/>
            <Textarea withAsterisk label="För väl godkänt" name="requirementsVG" size="xs" {...form.getInputProps('requirementsVG')}/>

            <Textarea withAsterisk label="Kommentar" name="comment" size="xs" {...form.getInputProps('comment')}/>

            <Group position="center" mt="xl">
                <Button size="sm" type="submit">
                    Spara
                </Button>
            </Group>
        </form>
    </Container>
}