import {TableScrollArea} from "../components/table/TableSticky";
import {
    Button,
    Checkbox,
    Container,
    createStyles,
    Divider, Modal,
    MultiSelect,
    Popover,
    Select,
    Space,
    Stack, TextInput
} from "@mantine/core";
import {TableRowGradeChart, TableRowGradeDropdown, TableRowString} from "../components/table/TableRow";
import {useRecoilState, useRecoilValue} from "recoil";
import {columnCategoryState, profileState, programmesState, studentData, tableColumns} from "../state";
import {useEffect, useState} from "react";
import ColumnDrawer from "./components/ColumnDrawer";
import StudentDrawer from "./components/StudentDrawer";
import {Column, ColumnCategory, ColumnConfiguration, ColumnType, Course, getColumnElement} from "../general/general";
import {IconCategory} from "@tabler/icons";

const useStyles = createStyles((theme, _, getRef) => ({
    header: {
        position: 'sticky',
        top: 0,
        zIndex: 10,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
        transition: 'box-shadow 150ms ease',

        '&::after': {
            content: '""',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            borderBottom: `1px solid ${
                theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]
            }`,
        },
    },

    tableContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10px',
        height: '100%'
    },

    viewHeader: {
        width: '100%',
        height: '70px',
        borderBottom: '1px solid #e9ecef',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },

    columnListItem: {
        marginLeft: '5px',
        fontSize: '12px',
        border: '1px solid #e9ecef',
        padding: '2px',
        borderRadius: '4px',
        cursor: 'pointer',

        '&:hover': {
            border: '2px solid #e9ecef',
        }
    },

    columnListContainer: {
        display: 'flex',
        marginRight: 'auto',
        alignItems: 'center',
    }
}));

function toTableColumns(columns: any): any {
    return columns;
}

function toTableColumnsWithCourses(columns: any, programme: any): any {
    let data = toTableColumns(columns);

    let courses = programme.courses.map((course: any) => {
        return {
            key: course.name,
            name: course.name,
            type: ColumnType.CourseGrade,
            categories: [],
            extendable: true
        }
    });
    return data.concat(courses);
}

// TODO: Fix så att man kan ha configs på utbildningsspecifika kolumner

function getColumnsWithCategory(category: ColumnCategory, columns: Column[]): Column[] {
    return columns.filter(all => all.categories.includes(category));
}

export default function StudentsView(props: any) {
    const {cx, classes} = useStyles();
    const columnState = useRecoilValue(tableColumns);
    const categoryState = useRecoilValue(columnCategoryState);
    const programmes = useRecoilValue(programmesState);
    const [profile, setProfile] = useRecoilState(profileState);

    const [students, setStudents] = useRecoilState(studentData);
    const [select, setSelect] = useState<any>('all');
    const [columns, setColumns] = useState<Column[]>(columnState);
    const [creating, setCreating] = useState<boolean>(false);
    const [ignoredColumns, setIgnoredColumns] = useState<Column[]>([]);
    const [editing, setEditing] = useState<boolean>(false);
    const [configModal, setConfigModal] = useState<boolean>(false);
    const [configName, setConfigName] = useState<string>('');
    const [activeConfig, setActiveConfig] = useState<string | null>('default');

    const onCreate = (student: any) => {
        setStudents([...students, student]);
    }

    const onChange = (key: string, value: any, object: any, index: number) => {
        setStudents(students => students.map(all => {
            if (all !== object) return all;

            let obj = {...object};
            obj[key] = value;

            return obj;
        }));
    }

    const onCloseColumn = (column: Column) => {
        setIgnoredColumns([...ignoredColumns, column]);
    }

    const addColumn = (column: Column) => {
        setIgnoredColumns(ignoredColumns.filter(all => all !== column));
    }

    const checkColumn = (column: Column) => {
        setActiveConfig('default');
        if (ignoredColumns.includes(column)) {
            setIgnoredColumns(ignoredColumns.filter(all => all !== column));
        } else {
            setIgnoredColumns([...ignoredColumns, column]);
        }
    }

    const allColumnsChecked = (category: ColumnCategory): boolean => {
        let list = getColumnsWithCategory(category, columnState);
        for (let column of list) {
            if (ignoredColumns.includes(column)) {
                return false;
            }
        }

        return true;
    }

    const checkCategory = (category: ColumnCategory) => {
        setActiveConfig('default');
        if (allColumnsChecked(category)) {
            let list = getColumnsWithCategory(category, columnState);
            let tot = [];
            for (let column of list) {
                if (!ignoredColumns.includes(column)) {
                    tot.push(column);
                }
            }
            setIgnoredColumns([...ignoredColumns, ...tot]);
        } else {
            setIgnoredColumns(ignoredColumns.filter(all => !all.categories.includes(category)))
        }
    }

    const mapColumnDropdown = (): any => {
        let list: any[] = [];

        categoryState.forEach((category, index) => {
            list.push(<div key={category.name}>
                <Checkbox label={category.name} checked={allColumnsChecked(category)} onChange={() => checkCategory(category)} size="md"/>
                <Divider sx={{ marginTop: '7px' }}/>
            </div>);

            let columns = getColumnsWithCategory(category, columnState);
            for (let column of columns) {
                list.push(<div key={column.name}>
                    <Checkbox label={column.name} checked={!ignoredColumns.includes(column)} onChange={() => checkColumn(column)}/>
                </div>);
            }

            list.push(<Space h={10} key={index}/>);
        });

        return list;
    }

    const setConfig = (value: string | null) => {
        if (value === null) return;

        setActiveConfig(value);
        const config = profile.configs.find(all => all.name === value);
        setIgnoredColumns(config === undefined ? [] : columnState.filter(all => !config.columns.includes(all.name)));
    }

    const saveConfig = () => {
        let config = new ColumnConfiguration(configName);
        config.columns = columns.filter(all => !ignoredColumns.includes(all)).map(column => column.name);
        setProfile({...profile, configs: [...profile.configs, config]});

        setConfigModal(false);
        setConfigName('');
        setActiveConfig(configName);
    }

    let data = programmes.map(all => {
        return { value: all.name, label: 'Utbildning: ' + all.name };
    });

    const mapConfigs = (config: ColumnConfiguration): any => {
        return {
            value: config.name,
            label: 'Profil: ' + config.name,
        }
    }

    const configs = () => {
        let list = profile.configs.map(mapConfigs);

        list.push({
            value: 'default',
            label: 'Profil: ingen'
        });

        return list;
    }

    return <div>
        <StudentDrawer opened={creating} onClose={() => setCreating(false)} onCreate={onCreate}/>

        <Modal opened={configModal} onClose={() => setConfigModal(false)}>
            <TextInput label="Konfig namn" value={configName} onChange={event => setConfigName(event.target.value)}/>
            <Button sx={{ margin: '5px' }} variant='outline' onClick={() => saveConfig()}>Spara</Button>
        </Modal>

        <div>
            <div className={cx(classes.viewHeader)}>
                <div className={cx(classes.columnListContainer)}>
                    <Popover width={200} position="bottom" withArrow shadow="md">
                        <Popover.Target>
                            <Button sx={{ margin: '5px' }} variant='outline'>Kolumner</Button>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <Stack sx={{ maxHeight: '500px', overflow: 'auto' }}>
                                {mapColumnDropdown()}
                            </Stack>
                        </Popover.Dropdown>
                    </Popover>

                    <Select data={configs()} value={activeConfig} onChange={value => setConfig(value)}/>
                </div>

                {ignoredColumns.length === 0 ? null : <Button sx={{ margin: '5px' }} variant='outline' onClick={() => setConfigModal(true)}>Spara konfiguration</Button>}

                <Select placeholder="Alla"
                        data={[
                            { value: 'all', label: 'Utbildning: Alla' },
                            ...data,
                        ]}
                        value={select}
                        onChange={(value) => {
                            let col = value === 'all' ? toTableColumns(columnState) : toTableColumnsWithCourses(columnState, programmes.find(all => all.name === value));
                            setColumns(col);
                            setSelect(value);
                        }}
                />
                <Button sx={{ margin: '5px' }} variant={editing ? 'filled' : 'outline'} onClick={() => setEditing(prev => !prev)}>Redigeringsläge</Button>
                <Button sx={{ margin: '5px' }} variant='outline' onClick={() => setCreating(true)}>Ny studerande</Button>
            </div>
        </div>

        <div className={cx(classes.tableContainer)}>
            <Container fluid={true}>
                <TableScrollArea data={students} columns={columns.filter(all => !ignoredColumns.includes(all))} onChange={onChange} closableColumns={true} onClose={onCloseColumn} editMode={editing}/>
            </Container>
        </div>
    </div>;
}