import {Button, Drawer, Group, SimpleGrid, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import {useRecoilState, useSetRecoilState} from "recoil";
import {programmesState} from "../../state";
import {DatePicker} from "@mantine/dates";


/*

namn
yh nr
id nummer?
admin

 */

export default function StudentDrawer({ opened, onClose, onCreate }: { opened: boolean, onClose(): void, onCreate(student: any): void }) {
    const form = useForm({
        initialValues: {
            namn: '',
            email: '',
        },
        validate: {
            namn: (value) => value.trim().length < 2,
        }
    });

    const handleSubmit = (values: any) => {
        onCreate(values);
        onClose();
    }

    return <>
        <Drawer opened={opened} onClose={onClose} title={"Lägg till studerande"} size="xl" padding="xl">
            <form onSubmit={form.onSubmit(handleSubmit)}>
                <TextInput withAsterisk label="Namn" placeholder="Anders Persson" name="namn" {...form.getInputProps('namn')}/>
                <TextInput withAsterisk label="Email" placeholder="anders@persson.se" name="email" {...form.getInputProps('email')}/>

                <Group position="center" mt="xl">
                    <Button type="submit" size="md">
                        Spara
                    </Button>
                </Group>
            </form>
        </Drawer>
    </>
}