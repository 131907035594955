import {ActionIcon, Button, Drawer, Grid, Group, Select, SimpleGrid, Space, Text, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {columnCategoryState, programmesState} from "../../state";
import {DatePicker} from "@mantine/dates";
import {ColumnCategory} from "../../general/general";
import {IconPlus} from "@tabler/icons";
import {useState} from "react";


/*

namn
yh nr
id nummer?
admin

 */

export default function ColumnDrawer({ opened, onClose, onCreate }: { opened: boolean, onClose(): void, onCreate(course: any): void }) {
    const columnCategories = useRecoilValue(columnCategoryState);
    const [categories, setCategories] = useState<string[]>(['']);
    const categoryState = useRecoilValue(columnCategoryState);

    const form = useForm({
        initialValues: {
            name: '',
            key: '',
            type: 'Text',
            category: '',
        },
        validate: {
            name: (value) => value.trim().length < 2,
        }
    });

    const handleSubmit = (values: any) => {
        onCreate({...values, categories: categories.map(all => { return categoryState.find(cat => cat.name === all) }) });
        onClose();
    }

    const convertCategories = (category: ColumnCategory) => {
        return { value: category.name, label: category.name }
    }

    const addCategory = () => {
        setCategories([...categories, '']);
    }

    const editCategory = (index: number, value: any) => {
        setCategories(categories.map((category: any, i) => {
            if (i !== index) return category;
            return value;
        }));
    }

    return <>
        <Drawer opened={opened} onClose={onClose} title={"Skapa ny kolumn"} size="xl" padding="xl">
            <form onSubmit={form.onSubmit(handleSubmit)}>
                <TextInput withAsterisk label="Namn" placeholder="Email" name="name" {...form.getInputProps('name')}/>
                <TextInput withAsterisk label="Nyckel" placeholder="email" name="key" {...form.getInputProps('key')}/>
                <Select withAsterisk
                        label="Kolumn typ"
                        placeholder="Text"
                        data={[
                            { value: 'Text', label: 'Text' },
                            { value: 'Betygval', label: 'Betygval' },
                        ]}
                        name="type"
                />

                <Space mt={15}/>
                <Text size={13}>Kategorier</Text>

                {categories.map((category, index) => {
                    return <Grid key={index}>
                        <Grid.Col span={10}>
                            <Select placeholder="profil"
                                    data={columnCategories.map(convertCategories)}
                                    value={category}
                                    onChange={value => editCategory(index, value)}
                            />
                        </Grid.Col>
                        {
                            index === categories.length - 1 && <Grid.Col span={1}>
                                <ActionIcon onClick={addCategory}>
                                    <IconPlus />
                                </ActionIcon>
                            </Grid.Col>
                        }
                    </Grid>
                })}
                <Space mt={15}/>

                <Group position="center" mt="xl">
                    <Button type="submit" size="md">
                        Spara
                    </Button>
                </Group>
            </form>
        </Drawer>
    </>
}

export function CategoryDrawer({ opened, onClose, onCreate }: { opened: boolean, onClose(): void, onCreate(course: any): void }) {
    const form = useForm({
        initialValues: {
            name: '',
        },
        validate: {
            name: (value) => value.trim().length < 2,
        }
    });

    const handleSubmit = (values: any) => {
        onCreate(values);
        onClose();
    }

    return <>
        <Drawer opened={opened} onClose={onClose} title={"Skapa ny kategori/familj"} size="xl" padding="xl">
            <form onSubmit={form.onSubmit(handleSubmit)}>
                <TextInput withAsterisk label="Namn" placeholder="profil" name="name" {...form.getInputProps('name')}/>

                <Group position="center" mt="xl">
                    <Button type="submit" size="md">
                        Spara
                    </Button>
                </Group>
            </form>
        </Drawer>
    </>
}