import {Container, createStyles, SimpleGrid, Text} from "@mantine/core";
import {NavbarMinimal} from "./NavbarMinimal";
import {Route, Routes} from "react-router-dom";
import StudentsView from "./views/Students";
import ConfigurationView from "./views/Configuration";
import {SettingsView} from "./views/Settings";
import {AccountView} from "./views/Account";
import {useState} from "react";
import {TableRowGradeDropdown, TableRowString} from "./components/table/TableRow";
import PlanningView from "./views/Planning";
import ClassView from "./views/Classes";
import ProgrammeView from "./views/Programme";
import GroupView from "./views/Group";

const useStyles = createStyles((theme) => ({
    layout: {
        display: 'flex',
        alignItems: 'center',
    },

    mainContainer: {
        width: '100%',
        height: '100vh'
    }
}));

/*

[
        { column: 'Namn', key: 'name', element: TableRowString },
        { column: 'Email', key: 'email', element: TableRowString },
        { column: 'Företag', key: 'company', element: TableRowString },
        { column: 'Betyg', key: 'grade', element: TableRowGradeDropdown },
    ]

 */

export default function Main() {
    const { classes, cx } = useStyles();

    return <div className={cx(classes.layout)}>
        <div>
            <NavbarMinimal/>
        </div>
        <div className={cx(classes.mainContainer)}>
            <Routes>
                {/*<Route path="/" element={<Text>Home Sweet Home</Text>}/>*/}
                <Route path="/" element={<StudentsView />}/>
                <Route path="/tabell" element={<StudentsView />}/>
                <Route path="/konfiguration" element={<ConfigurationView />}/>
                <Route path="/inställningar" element={<SettingsView />}/>
                <Route path="/konto" element={<AccountView />}/>
                <Route path="/planering" element={<PlanningView />}/>
                <Route path="/klasser" element={<ClassView />}/>
                <Route path="/utbildning/:id" element={<ProgrammeView />}/>
                <Route path="/intag/:id" element={<GroupView />}/>
            </Routes>
        </div>
    </div>
}