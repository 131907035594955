/*

Per klass kolumn
Global kolumn
Per användare kolumn

 */


import React from "react";
import {
    TableRowComment,
    TableRowCourseGrade, TableRowGradeChart,
    TableRowGradeDropdown,
    TableRowNamedObject, TableRowNamedObjectList,
    TableRowProps,
    TableRowString, TableRowStringList
} from "../components/table/TableRow";

export interface Programme {
    name: string;
    yhnr: string;
    confirmationYear: number;
    courses: Course[];
    comment: string;
}

export interface Group {
    name: string;
    yhnr: string;
    programme: string;
    hours: number;
    formOfStudy: string;
    speedOfStudy: number;
    comment: string;
}

export class Profile {
    public configs: ColumnConfiguration[] = [];
}

export class ColumnConfiguration {
    public name: string;
    public columns: String[] = [];

    constructor(name: string) {
        this.name = name;
    }
}

export class Course {
    public name: string;
    public startDate: Date;
    public endDate: Date;
    public points: number;

    public version: string = '';
    public languages: string = '';
    public createdBy: string = '';
    public confirmedBy: string = '';

    public grades: string = '';
    public requirementsG: string = '';
    public requirementsVG: string = '';

    public goal: string = '';
    public knowledge: string[] = [];
    public skills: string[] = [];
    public competences: string[] = [];

    public comment: string = '';

    constructor(name: string, points: number, startDate: Date, endDate: Date) {
        this.name = name;
        this.points = points;
        this.startDate = startDate;
        this.endDate = endDate;
    }
}

export interface Student { // NEEDED?

}

export class ColumnCategory {
    public name: string;

    constructor(name: string) {
        this.name = name;
    }
}

export enum ColumnType {
    Text = "Text",
    TextList = "Lista",
    Comment = "Kommentar",
    GradeChoice = "Betygval",
    CourseGrade = "Kursbetyg",
    NamedObject = "Objektnamn",
    NamedObjectList = "Objektnamn lista",
}

export class Column {
    public name: string;
    public key: string;
    public type: ColumnType;
    public categories: ColumnCategory[];
    public extendable: boolean;

    constructor(name: string, key: string, type: ColumnType, categories: ColumnCategory[], extendable: boolean) {
        this.name = name;
        this.key = key;
        this.type = type;
        this.categories = categories;
        this.extendable = extendable;
    }
}

export function getColumnElement(column: Column): React.FC<TableRowProps> {
    switch (column.type) {
        case ColumnType.GradeChoice:
            return TableRowGradeDropdown;
        case ColumnType.CourseGrade:
            return TableRowGradeChart;
        case ColumnType.Text:
            return TableRowString;
        case ColumnType.TextList:
            return TableRowStringList;
        case ColumnType.Comment:
            return TableRowComment;
        case ColumnType.NamedObjectList:
            return TableRowNamedObjectList;
        case ColumnType.NamedObject:
            return TableRowNamedObject;
        default:
            return TableRowString;
    }
}