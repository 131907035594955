import {TableRowString} from "../components/table/TableRow";
import {TableAction, TableScrollArea} from "../components/table/TableSticky";
import {Button, Container, createStyles, Tooltip} from "@mantine/core";
import {IconPencilPlus, IconTrash} from "@tabler/icons";
import {useRecoilState} from "recoil";
import {columnCategoryState, tableColumns} from "../state";
import {useState} from "react";
import ColumnDrawer, {CategoryDrawer} from "./components/ColumnDrawer";
import {Column, ColumnType} from "../general/general";

/*

Create
Delete
Modify

 */
const useStyles = createStyles((theme, _, getRef) => ({
    action: {
        cursor: 'pointer',

        '&:hover': {
            scale: '1.1',
        }
    },

    header: {
        width: '100%',
        height: '70px',
        borderBottom: '1px solid #e9ecef',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },

    tableContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
        height: '100%'
    },
}));

enum Drawer {
    None,
    Column,
    Category
}

export default function ConfigurationView(props: any) {
    const { classes, cx } = useStyles();
    const [columns, setColumns] = useRecoilState(tableColumns);
    const [categories, setCategories] = useRecoilState(columnCategoryState);
    const [creating, setCreating] = useState<Drawer>(Drawer.None);

    let categoryConfig = [
        new Column('Namn', 'name', ColumnType.Text, [], false),
        new Column('Kolumner', 'columns', ColumnType.Text, [], false),
    ]

    let config = [
        new Column('Kolumn', 'name', ColumnType.Text, [], false),
        new Column('Typ', 'type', ColumnType.Text, [], false),
        new Column('Nyckel', 'key', ColumnType.Text, [], false),
        new Column('Kategorier', 'categories', ColumnType.NamedObjectList, [], false),
    ]

    const onChange = (key: string, value: any, object: any, index: number) => {
        object[key] = value;
        console.log("Changed to " + value + " for " + object['name'] + " index: " + index);
    }

    const onCreateColumn = (column: any) => {
        console.log(column);
        setColumns([...columns, column]);
    }

    const onCreateCategory = (category: any) => {
        setCategories([...categories, category]);
    }

    let actions = [
        // {
        //     element: (<Tooltip label={"Modifiera"} position="right" transitionDuration={0}>
        //         <div>
        //             <IconPencilPlus color="gray" width="20px" height="20px" className={cx(classes.action)}/>
        //         </div>
        //     </Tooltip>),
        //
        //     handler: (i: number): TableAction => {
        //         return TableAction.EditRow;
        //     }
        // },
        {
            element: (<Tooltip label={"Radera"} position="right" transitionDuration={0}>
                <div>
                    <IconTrash color="gray" width="20px" height="20px" className={cx(classes.action)}/>
                </div>
            </Tooltip>),

            handler: (i: number): TableAction => {
                setColumns(columns.filter((column: any, index: number) => index !== i));
                return TableAction.None;
            }
        }

    ];


    return <>
        <div>
            <ColumnDrawer opened={creating === Drawer.Column} onClose={() => setCreating(Drawer.None)} onCreate={onCreateColumn}/>
            <CategoryDrawer opened={creating === Drawer.Category} onClose={() => setCreating(Drawer.None)} onCreate={onCreateCategory}/>

            <div className={cx(classes.header)}>
                <Button sx={{ marginRight: '6px' }} variant='outline' onClick={() => setCreating(Drawer.Column)}>Ny kolumn</Button>
                <Button sx={{ marginRight: '6px' }} variant='outline' onClick={() => setCreating(Drawer.Category)}>Ny kategori</Button>
            </div>

            <div className={cx(classes.tableContainer)}>
                <Container fluid={true}>
                    <h3>Kategorier</h3>
                    <TableScrollArea data={categories.map(all => {
                        return {
                            name: all.name,
                            columns: columns.reduce((prev, curr) => prev + (curr.categories.includes(all) ? 1 : 0), 0)
                        }
                    })} columns={categoryConfig} onChange={onChange}/>
                </Container>

                <Container fluid={true}>
                    <h3>Kolumner</h3>
                    <TableScrollArea data={columns} columns={config} onChange={onChange} actions={actions}/>
                </Container>
            </div>
        </div>
    </>;
}